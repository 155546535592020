'use client';

import React, { useState } from 'react';
import { MenuProps } from 'antd';
import { DASHBOARD_URL } from '@/utils/config';
import { MenuInfo } from 'rc-menu/lib/interface';
import { Analytics } from '@/libs/GoogleAnalytics';
import {
  faMemo,
  faTag,
  faUser,
  faMessages,
  faLightbulb,
  faUsersMedical,
  faCapsules,
  faFlaskVial,
} from '@awesome.me/kit-b89bd90d95/icons/classic/solid';
import CustomMenu from '@/components/global/CustomMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MobileMenuDefaultLink from '@/components/global/typography/links/MobileMenuDefaultLink';

type MenuItem = Required<MenuProps>['items'][number];
type MenuClickEventHandler = (info: MenuInfo) => void;

interface IMobileNavigationProps {
  user: boolean;
  handleOnClose: MenuClickEventHandler;
}

const MobileNavigation = ({ user, handleOnClose }: IMobileNavigationProps) => {
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const items: MenuItem[] = [
    getItem(
      'Solutions',
      'sub1',
      <FontAwesomeIcon
        icon={faLightbulb}
        className={'mr-4 solutions-collapse-icon'}
      />,
      [
        getItem(
          <MobileMenuDefaultLink href={'/ai-doctor'} className={'p-0'}>
            <FontAwesomeIcon
              icon={faMessages}
              color={'#525564'}
              className={'mr-4'}
            />
            AI Doctor
          </MobileMenuDefaultLink>,
          'ai-health-assistant',
        ),
        getItem(
          <MobileMenuDefaultLink href={'/supplements'} className={'p-0'}>
            <FontAwesomeIcon
              icon={faCapsules}
              color={'#525564'}
              className={'mr-4'}
            />
            Supplements
          </MobileMenuDefaultLink>,
          'supplements',
        ),
        getItem(
          <MobileMenuDefaultLink
            href={'/lab-test-interpretation'}
            className={'p-0'}
          >
            <FontAwesomeIcon
              icon={faFlaskVial}
              color={'#525564'}
              className={'mr-4'}
            />
            Lab Test Interpretation
          </MobileMenuDefaultLink>,
          'lab-test-interpretation',
        ),
        getItem(
          <MobileMenuDefaultLink href={'/second-opinion'} className={'p-0'}>
            <FontAwesomeIcon
              icon={faUsersMedical}
              color={'#525564'}
              className={'mr-4'}
            />
            Second Opinion
          </MobileMenuDefaultLink>,
          'second-opinion',
        ),
      ],
    ),
    getItem(
      <MobileMenuDefaultLink href={'/pricing'} className={'p-0 font-semibold'}>
        <FontAwesomeIcon icon={faTag} className={'mr-4'} />
        Pricing
      </MobileMenuDefaultLink>,
      'pricing',
    ),
    getItem(
      'Resources',
      'sub2',
      <FontAwesomeIcon
        icon={faMemo}
        className={'mr-2 resources-collapse-icon'}
      />,
      [
        getItem(
          <MobileMenuDefaultLink href={'/blog'} className={'p-0 font-medium'}>
            Blog
          </MobileMenuDefaultLink>,
          'blog',
        ),
        getItem(
          <MobileMenuDefaultLink
            href={'/symptoms-guide'}
            className={'p-0 font-medium'}
          >
            Symptoms Guide
          </MobileMenuDefaultLink>,
          'symptoms-guide',
        ),
        getItem(
          <MobileMenuDefaultLink
            href={'/knowledge-base'}
            className={'p-0 font-medium'}
          >
            Knowledge Base
          </MobileMenuDefaultLink>,
          'knowledge-base',
        ),
        getItem(
          <MobileMenuDefaultLink
            className={'p-0 font-medium'}
            href={'/glossary'}
          >
            Glossary
          </MobileMenuDefaultLink>,
          'glossary',
        ),
      ],
    ),
  ];

  if (user) {
    const profileItems: MenuItem[] = [
      getItem(
        <MobileMenuDefaultLink
          href={`${DASHBOARD_URL}`}
          onClick={() => Analytics.sendLoginStartEventToGA()}
        >
          My Dashboard
        </MobileMenuDefaultLink>,
        'profile',
        <FontAwesomeIcon icon={faUser} className={'mr-4'} />,
      ),
    ];

    items.unshift(...profileItems);
  }

  const onOpenChange = (_openKeys: string[]) => {
    const openKeysFiltered: string[] = _openKeys.filter(
      (_openKey: string) => !openKeys.includes(_openKey),
    );
    setOpenKeys(openKeysFiltered);
  };

  return (
    <CustomMenu
      selectable={false}
      mode="inline"
      style={{ width: '100%' }}
      items={items}
      onClick={handleOnClose}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
    />
  );
};

export default MobileNavigation;
