'use client';

import React, { useState } from 'react';
import styled from 'styled-components';
import { Dropdown, MenuProps } from 'antd';
import {
  faCapsules,
  faChevronDown,
  faChevronUp,
  faFlaskVial,
  faMessages,
  faUsersMedical,
} from '@awesome.me/kit-b89bd90d95/icons/classic/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuLink from '@/components/global/typography/links/MenuLink';
import MobileMenuDefaultLink from '@/components/global/typography/links/MobileMenuDefaultLink';

const StyledDropdown = styled(Dropdown)`
  &&& {
    .solution-dropdown-overlay {
      top: 40px !important;
      border-radius: 4px;
      border: 1px solid #e1e3e4;
      padding: 12px;
      width: 306px;
      box-shadow: 0 5px 20px 0 #272a3d0d;
      background-color: #fff;
      ul.ant-dropdown-menu {
        box-shadow: none;
        border-radius: 0;
        padding: 0;
        .ant-dropdown-menu-item {
          padding: 8px 12px;
          height: 44px;
          &:hover {
            background-color: #f8f8f9;
            border-radius: 4px;
          }
        }
      }
    }
    &.menu-default-color {
      color: #272a3d;
    }
  }
`;

const solutionMenuItems: MenuProps['items'] = [
  {
    label: (
      <MobileMenuDefaultLink className={'font-medium'} href={'/ai-doctor'}>
        <FontAwesomeIcon
          icon={faMessages}
          color={'#7D7F8B'}
          className={'mr-3 icon'}
        />
        AI Doctor
      </MobileMenuDefaultLink>
    ),
    key: 'ai-doctor',
  },
  {
    label: (
      <MobileMenuDefaultLink href={'/supplements'} className={'font-medium'}>
        <FontAwesomeIcon
          icon={faCapsules}
          color={'#7D7F8B'}
          className={'mr-3 icon'}
        />
        Supplements
      </MobileMenuDefaultLink>
    ),
    key: 'supplements',
  },
  {
    label: (
      <MobileMenuDefaultLink
        href={'/lab-test-interpretation'}
        className={'font-medium'}
      >
        <FontAwesomeIcon
          icon={faFlaskVial}
          color={'#7D7F8B'}
          className={'mr-3 icon'}
        />
        Lab Test Interpretation
      </MobileMenuDefaultLink>
    ),
    key: 'lab-test',
  },
  {
    label: (
      <MobileMenuDefaultLink className={'font-medium'} href={'/second-opinion'}>
        <FontAwesomeIcon
          icon={faUsersMedical}
          color={'#7D7F8B'}
          className={'mr-3 icon'}
        />
        Second Opinion
      </MobileMenuDefaultLink>
    ),
    key: 'second-opinion',
  },
];

const SolutionsDropdown = () => {
  const [isDropdownHovered, setIsDropdownHovered] = useState(false);
  const [open, setOpen] = useState(false);

  const dropdownVisibilityChange = (open: boolean) => {
    setOpen(open);
  };
  return (
    <StyledDropdown
      dropdownRender={(menus) => (
        <div
          onMouseLeave={() => setIsDropdownHovered(false)}
          onMouseOver={() => setIsDropdownHovered(true)}
          className={'dropdown-wrapper'}
        >
          {menus}
        </div>
      )}
      getPopupContainer={(triggerNode) => triggerNode}
      overlayClassName={'solution-dropdown-overlay'}
      menu={{ items: solutionMenuItems }}
      className={'mr-12 solution-dropdown'}
      onOpenChange={dropdownVisibilityChange}
    >
      <MenuLink
        className={`${isDropdownHovered ? 'menu-default-color' : ''}`}
        href={'#'}
        onClick={(e) => e.preventDefault()}
      >
        Solutions
        <FontAwesomeIcon
          className={'ml-2 solutions-angle'}
          icon={open ? faChevronUp : faChevronDown}
          size={'xs'}
        />
      </MenuLink>
    </StyledDropdown>
  );
};

export default SolutionsDropdown;
